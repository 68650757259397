<template>
  <EditDialog width="50%" :isShow.sync="isDialogShow" @submit="onDeptSubmit" submitBtnText="确认">
    <div slot="title" class="title">选择部门</div>
    <div slot="content" class="content deptContent" v-loading="deptTreeLoading">
      <el-tree
        ref="tree"
        default-expand-all
        show-checkbox
        node-key="id"
        :data="deptTree"
        :default-checked-keys="selectedTreeData"
        :props="{ children: 'children', label: 'deptName' }"
        :expand-on-click-node="false"
        :check-on-click-node="true"
        :check-strictly="true"
        :render-content="renderContent"
        accordion
      >
      </el-tree>
    </div>
  </EditDialog>
</template>

<script>
export default {
  name: 'DeptTreeSelectDialog',
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
  },
  props: {
    isDeptTreeShow: {
      default: false,
      type: Boolean,
    },
    selectedTreeData: {
      default: () => {
        return []
      },
      type: Array,
    },
  },
  data() {
    return {
      isDialogShow: false,
      deptTreeLoading: false,
      deptTree: [],
      renderContent: function (h, { node, data, store }) {
        let addElement = arguments[0]
        if (data.level == 1) {
          return addElement('span', [
            addElement('i', { class: 'iconfont iconcompany iconColor' }),
            addElement('span', { class: 'deptName' }, arguments[1].node.label),
          ])
        } else if (data.level == 2) {
          return addElement('span', [
            addElement('i', { class: 'iconfont iconcangku iconColor' }),
            addElement('span', { class: 'deptName' }, arguments[1].node.label),
          ])
        } else if (data.level == 3) {
          return addElement('span', [
            addElement('i', { class: 'iconfont iconconnections iconColor' }),
            addElement('span', { class: 'deptName' }, arguments[1].node.label),
          ])
        } else if (data.level == 4) {
          return addElement('span', [
            addElement('i', { class: 'iconfont iconCustomermanagement iconColor' }),
            addElement('span', { class: 'deptName' }, arguments[1].node.label),
          ])
        }
      },
    }
  },
  created() {
    this.getDeptTree()
  },
  watch: {
    isDeptTreeShow: {
      immediate: true,
      handler: function (newVal) {
        this.isDialogShow = newVal
      },
    },
    isDialogShow: {
      immediate: true,
      handler(newVal) {
        this.$emit('update:isDeptTreeShow', newVal)
      },
    },
  },
  methods: {
    getDeptTree() {
      this.deptTreeLoading = true
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptTree = res.data
          this.deptTreeLoading = false
        })
        .catch(err => {
          console.log(err)
        })
    },

    onDeptSubmit() {
      this.$emit('getDeptList', this.$refs.tree.getCheckedNodes())
      this.isDialogShow = false
    },
  },
}
</script>

<style scoped lang="scss">
/deep/.deptContent {
  min-height: 350px;
  max-height: 500px;
  overflow: auto;
}
/deep/.deptName {
  font-size: 14px;
}

/deep/.iconColor {
  color: #399ffb;
}
</style>
